@import 'styles/breakpoints';
@import 'styles/colors';

.Grid {
  display: grid;
}

.noOverflow {
  overflow-x: auto;
}

.Background {
  @include media('big_mobile') {
    padding: 36px 46px 10px;
  }

  @include media('laptop') {
    padding: 24px 95px 56px;
  }

  padding: 36px 26px 10px;
}

.sectionContent {
  position: relative;
}

.Header {
  @include media('laptop') {
    margin-bottom: 24px;
  }

  @include media('desktop') {
    margin-bottom: 64px;
  }

  margin-bottom: 40px;
  text-align: center;
}

.Content {
  @include media('laptop') {
    gap: 24px;
    padding-bottom: 56px;
  }

  @include media('desktop') {
    gap: 87px;
    padding-bottom: 56px;
  }

  will-change: scroll-position;
  cursor: grab;

  display: grid;
  grid-auto-flow: column;
  column-gap: 16px;
  align-items: flex-start;

  padding-bottom: 36px;
}

[dir='ltr'] {
  .review {
    direction: ltr;
  }
}

[dir='rtl'] {
  .review {
    direction: rtl;
  }
}

.review {
  cursor: grab;
  scroll-snap-align: center;

  display: grid;
  grid-template-areas:
    'header'
    'desc'
    'readMore'
    'caption';
  grid-template-columns: 1fr;
  row-gap: 16px;

  margin: 0 10px;
  padding: 24px;

  background-color: $background-80;
  border-radius: 24px;

  &.fullTextShown {
    grid-template-areas:
      'header'
      'desc'
      'caption';
  }

  .header {
    @include media('laptop', 'desktop') {
      overflow: hidden;
      flex-direction: row;
      justify-self: start;
    }

    display: flex;
    flex-direction: column;
    column-gap: 24px;
    align-items: center;
    justify-self: center;
  }

  .avatar {
    @include media('big_mobile') {
      width: 128px;
      height: 128px;
    }

    @include media('laptop') {
      width: 60px;
      height: 60px;
      margin-bottom: unset;
    }

    @include media('desktop') {
      width: 80px;
      height: 80px;
    }

    pointer-events: none;

    overflow: hidden;
    grid-area: avatar;
    justify-self: center;

    width: 80px;
    height: 80px;
    margin-bottom: 16px;

    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .name {
    @include media('laptop', 'desktop') {
      align-self: center;
      justify-self: start;
      white-space: pre-wrap;
    }

    @include media('mobile') {
      text-wrap: nowrap;
    }

    grid-area: name;
    justify-self: center;
    color: $accent-second;
  }

  .description {
    @include media('big_mobile') {
      height: 120px;
    }

    @include media('laptop') {
      height: 168px;
      text-align: start;
    }

    @include media('desktop') {
      height: 178px;
    }

    position: relative;

    overflow: hidden;
    grid-area: desc;
    gap: 16px;
    justify-self: center;

    height: 192px;

    color: $text-30;
    text-align: center;
    white-space: pre-wrap;

    &::before {
      cursor: grab;
      content: '';

      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
    }

    &.fullTextShown {
      height: auto;
    }
  }

  .caption {
    @include media('laptop', 'desktop') {
      gap: 12px;
      justify-self: start;
    }

    @include media('big_mobile') {
      gap: 12px;
    }

    display: flex;
    grid-area: caption;
    gap: 3px;
    align-items: center;
    justify-self: center;

    .reviewEarningsValue {
      @include media('desktop') {
        font-size: 36px;
        line-height: 46px;
      }

      font-size: 24px;
      line-height: 30px;
      color: $accent-second;
    }
  }
}

.social {
  position: absolute;
  top: 8px;
  right: 8px;

  display: grid;
  row-gap: 8px;
  align-items: center;

  img {
    width: 12px;
    height: 12px;
  }
}

.buttonWrapper {
  @include media('laptop', 'desktop') {
    justify-content: center;
  }

  @include media('laptop') {
    padding: 0 0 24px;
  }

  @include media('desktop') {
    padding: 0 0 40px;
  }

  display: flex;
  justify-content: stretch;
  padding: 0 24px 36px;
}

.Button {
  @include media('laptop', 'desktop') {
    justify-self: center;
    width: auto;
  }

  @include media('laptop', 'desktop') {
    .mobile {
      display: none;
    }

    button.desktop {
      display: block;
    }
  }

  display: block;
  justify-content: center;
  width: 100%;
  text-align: center;

  .mobile {
    font-size: 16px;
    text-transform: uppercase;
  }

  .desktop {
    display: none;
  }
}

.readMoreBtn {
  cursor: pointer;

  grid-area: readMore;

  color: #0288ff;

  background-color: transparent;
  border: none;

  &:hover {
    opacity: 0.8;
  }
}

.gain {
  grid-area: gain;
  font-size: 12px;
  text-align: end;
}

.carouselWrapper {
  position: relative;
  padding-bottom: 56px;
}

.dot {
  @include media('laptop') {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 12px;
  }

  cursor: pointer;

  display: none;

  width: 10px;
  height: 10px;
  margin: 0 8px;
  padding: 0;

  opacity: 1;
  background-color: $background-80;
  border: none;
  border-radius: 50%;

  transition: opacity 0.25s ease-in;

  @media (hover: hover) {
    &:hover {
      background-color: $background-70;
    }
  }
}

.dotActive {
  background-color: $accent-second;

  &:hover {
    opacity: 0.8;
    background-color: $accent-second;
  }
}

.arrow {
  @include media('big_mobile') {
    padding: 50px 23px;
  }

  @include media('laptop') {
    margin-top: 0;
    padding: 0 18px;
  }

  @include media('desktop') {
    margin-top: 20px;
  }

  box-sizing: border-box;
  width: 0;
  margin-top: -10px;
  padding: 35px 12px;

  color: $text_10;

  background: transparent;
  border: unset;

  &.prev {
    @include media('big_mobile') {
      left: -40px;
    }

    @include media('laptop') {
      left: -45px;
    }

    @include media('desktop') {
      left: -50px;
    }

    left: -20px;

    &::after {
      @include media('laptop') {
        font-size: 33px;
      }

      @include media('desktop') {
        font-size: 41px;
      }

      font-size: 24px;
    }
  }

  &.next {
    @include media('big_mobile') {
      right: -40px;
    }

    @include media('laptop') {
      right: -45px;
    }

    @include media('desktop') {
      right: -50px;
    }

    right: -20px;

    &::after {
      @include media('laptop') {
        font-size: 33px;
      }

      @include media('desktop') {
        font-size: 41px;
      }

      font-size: 24px;
    }
  }
}
