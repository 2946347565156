@import 'styles/breakpoints';

.BillingBackground {
  @include media('desktop') {
    padding: 96px 0;
  }

  padding: 64px 16px;
}

.Grid {
  display: grid;
}

.Header {
  @include media('desktop') {
    margin-bottom: 64px;
  }

  margin-bottom: 24px;
  text-align: center;
}

.Content {
  @include media('big_mobile') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media('laptop') {
    grid-template-columns: repeat(8, 1fr);
    row-gap: 64px;
    column-gap: 48px;
  }

  @include media('desktop') {
    row-gap: 96px;
  }

  display: grid;
  grid-auto-flow: dense;
  grid-auto-rows: 1fr;
  grid-template-columns: minmax(70px, 1fr);
  row-gap: 32px;
  column-gap: 48px;
  align-items: center;
  justify-items: center;

  height: 100%;

  text-align: center;

  .System {
    @include media('big_mobile') {
      max-width: 200px;
      padding: 0;
    }

    @include media('laptop') {
      grid-column: span 2;
      max-width: 256px;
    }

    @include media('desktop') {
      max-width: unset;
      height: 142px;
      padding: 0 48px;
    }

    display: flex;
    justify-content: center;

    width: 100%;
    height: 114px;
    padding: 0 80px;

    &:nth-child(2) {
      height: 70px;
    }

    &:nth-child(5) {
      height: 106px;
    }

    &:nth-child(6) {
      height: 70px;
    }

    &:nth-child(7) {
      height: 101px;
    }

    &:nth-child(8) {
      height: 105px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
