@import 'styles/breakpoints';
@import 'styles/colors';

.Background {
  @include media('laptop', 'desktop') {
    padding: 40px 36px;
  }

  padding: 36px 16px;
}

.Grid {
  display: grid;

  .Content {
    @include media('laptop') {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
      margin-bottom: 56px;
    }

    @include media('desktop') {
      gap: 20px;
    }

    display: grid;
    grid-auto-rows: auto;
    gap: 24px;
    margin-bottom: 36px;
  }

  .Header {
    @include media('laptop', 'desktop') {
      margin-bottom: 80px;
    }

    margin-bottom: 24px;
    text-align: center;
  }

  .Button {
    @include media('tablet', 'laptop', 'desktop') {
      justify-self: center;
      width: auto;
    }

    width: 100%;
  }
}
