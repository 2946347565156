@import 'styles/colors';
@import 'styles/breakpoints';

.Card {
  @include media('laptop') {
    grid-template-areas:
      'symbol symbol volatilitykey'
      'symbol symbol volatilityvalue'
      'plot plot profit'
      'plot plot trade';
    grid-template-columns: 0.8fr 0.8fr 1fr;
    grid-template-rows: repeat(4, auto);
    column-gap: 12px;

    padding: 24px;

    box-shadow: unset;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0 4px $background-70;
    }
  }

  display: grid;
  grid-template-areas:
    'symbol symbol symbol'
    'plot plot plot'
    'volatilitykey . profit'
    'volatilityvalue . profit'
    'trade trade trade ';
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, auto);

  padding: 16px;

  background-color: $background-80;
  border-radius: 24px;
}

.Profit {
  @include media('laptop', 'desktop') {
    row-gap: 4px;
    justify-self: start;
  }

  display: grid;
  grid-area: profit;
  grid-template-rows: auto 1fr;
  align-self: stretch;
  justify-self: end;

  .Key {
    align-self: flex-end;
    text-wrap: nowrap;
  }
}

.Symbol {
  @include media('laptop') {
    margin-bottom: 16px;
  }

  display: flex;
  grid-area: symbol;
  column-gap: 16px;
  align-items: center;

  margin-bottom: 8px;

  img {
    height: 100%;
  }
}

.Plot {
  @include media('laptop', 'desktop') {
    margin-bottom: unset;
  }

  display: flex;
  grid-area: plot;
  margin-bottom: 16px;
}

.Volatility {
  @include media('laptop', 'desktop') {
    margin-bottom: 16px;
  }

  display: flex;
  grid-area: volatilityvalue;
  gap: 16px;
  align-items: center;

  text-align: start;
}

.VolatilityKey {
  @include media('laptop') {
    margin-bottom: 4px;
  }

  @include media('desktop') {
    margin-bottom: unset;
  }

  grid-area: volatilitykey;
  text-align: start;
}

.TradeButton {
  @include media('laptop') {
    align-self: flex-end;
    height: 37px;
    margin-top: unset;
    padding: 10px 16px;
  }

  @include media('desktop') {
    height: 56px;
    padding: 16px 24px;
  }

  grid-area: trade;

  height: 60px;
  margin-top: 16px;
  padding: 16px 24px;

  text-align: center;
  text-transform: uppercase;

  background-color: $accent-second;
  border-radius: 8px;
}

.arrowDown {
  @include media('laptop') {
    width: 18px;
    height: 18px;
  }

  @include media('desktop') {
    width: 30px;
    height: 30px;
  }

  width: 24px;
  height: 24px;
  padding: 4px;

  background-color: $brand-down;
  border-radius: 4px;
}

.arrowUp {
  @include media('laptop') {
    width: 18px;
    height: 18px;
  }

  @include media('desktop') {
    width: 30px;
    height: 30px;
  }

  width: 24px;
  height: 24px;
  padding: 4px;

  background-color: $brand-up;
  border-radius: 4px;
}
