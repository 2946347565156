@import 'styles/colors';
@import 'styles/breakpoints';

.Options_wrapper {
  @media screen and (max-width: $big-mobile) {
    position: relative;
    z-index: 1;
  }
}

.OptionsBackground {
  @include media('big_mobile') {
    padding: 24px;
  }

  @include media('laptop') {
    padding: 8px 105px;
  }

  @include media('desktop') {
    padding: 16px 0;
  }

  padding: 8px 24px;
}

.Header .Subtitle {
  cursor: pointer;
  position: relative;
}

.Grid {
  @include media('laptop') {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  display: flex;
  flex-flow: row wrap;
  gap: 16px;

  .Option {
    @include media('laptop') {
      flex: 0 30%;
      padding: 30px 0;
    }

    @include media('desktop') {
      padding: 34px 0;
    }

    display: flex;
    flex: 1 calc(50% - 8px);
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;

    padding: 26px 0;

    background: $background-80;
    border-radius: 16px;

    &:nth-of-type(2) {
      .Subtitle {
        text-transform: capitalize;
      }
    }

    .Title {
      @include media('desktop') {
        font-size: 80px;
      }

      font-size: 48px;
      font-weight: 700;
      color: $accent-second;
    }

    .Subtitle {
      color: $text-10;
      text-align: center;
    }

    .Subtitle_Link {
      color: $text-10;

      &:hover {
        color: $accent-second;
      }
    }

    .OptionText {
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      color: $text-60;
    }
  }
}

.OptionsList {
  @include media('big_mobile') {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @include media('laptop') {
    padding: 48px 36px;
  }

  @include media('desktop') {
    padding: 48px 0;
  }

  padding: 16px;

  .Cards {
    @include media('laptop') {
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(2, auto);
    }

    @include media('desktop') {
      gap: 48px;
    }

    display: grid;
    grid-template-rows: repeat(3, auto);
    gap: 24px;
    min-height: 300px;
  }
}

.card {
  display: flex;
  justify-self: center;

  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6) {
    @include media('laptop', 'desktop') {
      display: flex;
    }

    display: none;
  }
}

.Option {
  div,
  p {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &.loaded div,
  &.loaded p {
    opacity: 1;
  }
}
