@import 'styles/breakpoints';
@import 'styles/colors';

.Card {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 24px 36px;

  background-color: $background-80;
  border-radius: 24px;

  .Icon {
    margin-bottom: 24px;
  }

  .Title {
    @include media('desktop') {
      margin-bottom: 12px;
    }

    margin-bottom: 8px;
    text-align: center;
  }

  .Subtitle {
    color: $text-60;
    text-align: center;
  }
}

@include media('laptop', 'desktop') {
  .Card {
    padding: 48px 36px;

    .Icon svg {
      width: 80px;
      height: 80px;
    }
  }
}
