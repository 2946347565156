@import 'styles/breakpoints';

.Background {
  @include media('laptop', 'desktop') {
    overflow: hidden;
    min-height: 510px;
    padding: 96px 105px;
  }

  position: relative;
  padding: 64px 16px;
}

.Grid {
  position: relative;
  z-index: 1;
  display: grid;
}

.Titles {
  display: grid;
  line-height: 1.4;

  .Title {
    margin-bottom: 16px;
    text-align: center;
  }

  .Subtitle {
    @include media('laptop') {
      justify-self: center;
      width: 85%;
    }

    @include media('desktop') {
      width: 70%;
    }

    margin-bottom: 28px;
    text-align: center;
  }
}

.Content {
  .Mobile {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
  }

  .Desktop {
    @include media('laptop', 'desktop') {
      display: block;
    }

    display: none;

    .laptopLeft {
      @include media('desktop') {
        left: 0;
        width: 488px;
        height: unset;
      }

      position: absolute;
      bottom: 0;
      left: -96px;

      width: auto;
      height: 403px;

      object-fit: cover;
    }

    .laptopRight {
      @include media('desktop') {
        right: 0;
        width: 551px;
        height: unset;
      }

      position: absolute;
      top: 0;
      right: -15px;

      width: auto;
      height: 434px;

      object-fit: cover;
    }
  }
}

@include media('laptop', 'desktop') {
  .Titles {
    gap: 10px;
  }

  // .Content {
  // .Mobile {
  //   display: none;
  // }

  // .Desktop {
  //   display: block;

  //   .laptopLeft {
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //   }

  //   .laptopRight {
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //   }
  // }
  // }
}

.Button {
  justify-self: center;
}
