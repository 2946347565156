@import 'styles/breakpoints';
@import 'styles/colors';

.Background {
  @include media('laptop', 'desktop') {
    padding: 36px 61px 96px;
  }

  padding: 40px 16px;
}

.startSteps {
  padding-top: 42px;
  padding-bottom: 42px;
}

.grid {
  @include media('laptop') {
    row-gap: 48px;
  }

  @include media('desktop') {
    row-gap: 36px;
  }

  display: grid;
  row-gap: 16px;
  max-width: 100%;

  .cards {
    @include media('laptop', 'desktop') {
      flex-direction: row;
      align-items: start;
      justify-content: space-between;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    text-align: center;
  }
}

.card {
  @include media('laptop', 'desktop') {
    flex: 0 1 30%;
    padding: 48px 36px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 36px;

  .icon {
    margin-bottom: 24px;

    svg {
      @include media('laptop', 'desktop') {
        aspect-ratio: 16 / 14;
        width: 256px;
      }

      @include media('desktop') {
        width: 321px;
      }

      transform: scaleX(1);
      width: 180px;
      height: auto;
    }
  }

  .title {
    @include media('desktop') {
      margin-bottom: 16px;
    }
  }

  .text {
    color: $text-60;
    text-align: center;
  }
}

.Button {
  @include media('tablet', 'laptop', 'desktop') {
    justify-self: center;
    width: auto;
  }

  @include media('tablet', 'laptop', 'desktop') {
    .mobile {
      display: none;
    }

    button.desktop {
      display: block;
    }
  }

  width: 100%;

  .mobile {
    font-size: 16px;
    text-transform: uppercase;
  }

  .desktop {
    display: none;
  }
}
