@import 'styles/breakpoints';
@import 'styles/colors';

$android-bottom-padding: 40px;
$ios-bottom-padding: 70px;

.HeroBackground {
  @include media('big_mobile') {
    height: calc(100vh - 64px - 70px);
    padding: 24px;
  }

  @include media('laptop') {
    height: unset;
    padding: 48px 110px;
  }

  @include media('desktop') {
    padding: 24px 130px;
  }

  height: calc(100vh - 60px - $android-bottom-padding);
  padding: 24px 18px;

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    height: calc(100vh - 60px - $ios-bottom-padding);
  }

  & > div {
    height: 100%;
  }
}

.Grid {
  @include media('laptop', 'desktop') {
    grid-template-areas:
      '. . cover cover'
      'titles titles cover cover'
      'subtitle subtitle cover cover'
      'controls controls cover cover'
      '. . cover cover';
    grid-template-columns: 1fr 1fr 0.9fr 0.9fr;
    grid-template-rows: auto repeat(3, min-content) auto;

    height: unset;
    padding: unset;

    div.Cover {
      aspect-ratio: 14 / 16;
      height: unset;
      margin-top: -60px;
      margin-bottom: -60px;
      padding-bottom: unset;
    }

    .Controls {
      grid-area: controls;
      justify-self: flex-start;

      button {
        width: unset;
      }
    }
  }

  display: grid;
  grid-template-areas:
    'cover'
    'titles'
    'subtitle'
    'controls';
  grid-template-rows: 1fr repeat(3, auto);
  height: 100%;

  .Cover {
    position: relative;

    direction: ltr;
    grid-area: cover;
    place-self: center center;

    width: 100%;
    height: 100%;

    .phoneImage {
      object-fit: contain;
    }
  }

  .Controls {
    @include media('mobile', 'tablet') {
      button {
        width: 100%;
      }
    }

    grid-area: controls;
  }
}

.Titles {
  @include media('laptop') {
    padding-bottom: 16px;
  }

  @include media('desktop') {
    gap: 8px;
    padding-bottom: 24px;
  }

  display: flex;
  grid-area: titles;
  flex-direction: column;
  padding-bottom: 8px;
}

.FrontTitle {
  @include media('laptop') {
    padding-bottom: 12px;
  }

  color: $text-60;
}

.Title {
  color: $text-10;
}

.Subtitle {
  @include media('laptop') {
    padding-bottom: 36px;
  }

  @include media('desktop') {
    padding-bottom: 64px;
  }

  grid-area: subtitle;
  padding-bottom: 32px;
  color: $text-40;
}
