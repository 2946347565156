@import 'styles/colors';
@import 'styles/breakpoints';

.button {
  @include media('big_mobile') {
    padding: 20px;
  }

  @include media('laptop') {
    padding: 20px 16px 20px 20px;
  }

  @include media('desktop') {
    padding: 24px 16px 24px 32px;
    font-size: 24px;
  }

  cursor: pointer;

  position: relative;

  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: 16px;

  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: $text-10;
  letter-spacing: 0.5px;

  background-color: $accent-second;
  border-radius: 8px;

  transition: all 0.2s;

  &:hover {
    color: $accent-second;
    background-color: transparent;
    box-shadow: 0 0 0 1px $accent-second inset;
  }
}
